import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import useEnrollmentCompleteImages from "./hooks/useEnrollmentCompleteImages"
import Button from "elements/Button"
import Layout from "layout"
import Container from "layout/Container"
import { redirectBasedOnPermissions } from "../../Auth/services/permissions"

import { AppContext } from "../../../context/AppContext"
import styles from "./utils/enrollmentComplete.module.scss"
import { getSignedInUser } from "../../Auth/services/user"

const EnrollmentComplete = (props) => {
  const { pageContext } = props
  const { module, content } = pageContext
  const { dispatch } = useContext(AppContext)

  const data = useEnrollmentCompleteImages()

  const handleOnClick = (e) => {
    let userData = getSignedInUser().userData
    let chosen = sessionStorage.getItem("programChosen")
    if (chosen) {
      redirectBasedOnPermissions(chosen, userData?.permissions)
    } else {
      navigate("/")
    }
  }

  useEffect(() => {
    const restartDetails = async () => {
      await dispatch({ type: "RESET_DETAILS" })
    }
    restartDetails()
  }, [dispatch])

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: false }}
      seoTitle={module.seoTitle}
      pageContext={pageContext}
    >
      <Container isCentered>
        <center>
          <Container isCentered mobile={10} tablet={6} desktop={4}>
            {content.icon && (
              <Img
                fluid={data[content.icon].childImageSharp.fluid}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            )}
          </Container>
          <h1 className="title is-size-1-desktop is-size-3-mobile my-2 has-text-primary">
            {module.title}
          </h1>
          <p className="pb-4 is-size-5">{content.text}</p>
          <Button
            onClick={handleOnClick}
            className="button is-primary is-medium"
          >
            Book Teleconsult
          </Button>
        </center>
      </Container>
    </Layout>
  )
}

export default EnrollmentComplete
